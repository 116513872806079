<template>
  <v-main class="">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="search"
            label="Buscar Usuario"
            rounded
            dense
            filled
            class=""
            append-icon="mdi-magnify"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-btn
            text
            block
            color="blue darken-4"
            dark
            @click="dialogCrear = true"
          >
            CREAR USUARIO
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="itemsFilter.length > 0">
        <v-col
          v-for="(usuario, index) in itemsFilter"
          :key="index"
          cols="12"
          md="3"
          sm="4"
          class="mx-auto"
        >       
          <Usuasiosector
            v-on:revisar="revisar"
            v-on:editar="editar"
            :informacion="usuario"
            v-on:eliminar="eliminar"
            :imagen="img"
            class="mx-auto"
          />
    
        </v-col>
      </v-row>
      <v-row v-else class="" align="center" justify="center">
        <div
          v-text="'No hay usuarios creados  este sector'"
          class="headline font-weight-light text-center"
        ></div>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogRevisar" fullscreen>
      <v-sheet rounded="" elevation="5" color="grey lighten-3">
        <v-container fluid>
          <v-row>
            <v-col cols="1">
              <v-btn
                fab
                text
                small
                color="blue darken-4"
                class="mt-3"
                @click="
                  dialogRevisar = false;
                  habilitarEditar = true;
                "
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-sheet
                elevation="5"
                rounded=""
                color="indigo darken-4"
                dark
                class="mt-4 mr-15 ml-n8"
              >
                <v-row>
                  <v-col>
                    <div
                      v-text="'Información del Usuario'"
                      class="headline font-weight-light text-center"
                    ></div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row class="ml-16">
            <v-col cols="12" sm="7" id="formulario_info_edited">
              <v-sheet id="info_personal" elevation="5" rounded="">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-sheet
                        color="indigo darken-4"
                        elevation="5"
                        dark
                        rounded=""
                        class="mt-4"
                      >
                        <v-row>
                          <v-col>
                            <div
                              v-text="'Información Personal'"
                              class="headline font-weight-light text-center ml-15 mt-1"
                            ></div>
                          </v-col>
                          <v-col cols="2">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  fab
                                  text
                                  @click="habilitarEditar = !habilitarEditar"
                                >
                                  <v-icon
                                    v-text="
                                      habilitarEditar == true
                                        ? 'mdi-pencil'
                                        : 'mdi-close'
                                    "
                                  >
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span
                                v-text="
                                  habilitarEditar == true
                                    ? 'Editar Información'
                                    : 'Cancelar Edición'
                                "
                              ></span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row id="formulario_indo_personal">
                    <v-col>
                      <v-container>
                        <v-form v-model="valid2">
                          <v-container v-if="habilitarEditar == true">
                            <v-row>
                              <v-col>
                                <v-text-field
                                  :rules="[
                                    (v) =>
                                      !!v || 'Es requerido asignar un nombre',
                                  ]"
                                  required
                                  prepend-icon="mdi-account"
                                  label="Nombre"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_revisado.nombre"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  type="number"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Es requerido asignar una identificacion',
                                  ]"
                                  required
                                  prepend-icon="mdi-card"
                                  label="Identificacion"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_revisado.identificacion"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Es requerido asignar un correo electronico',
                                    (v) =>
                                      /.+@.+\..+/.test(v) ||
                                      'Coloca un email valido',
                                  ]"
                                  required
                                  prepend-icon="mdi-email"
                                  label="Correo"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_revisado.correo"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-select
                                  :readonly="habilitarEditar"
                                  rounded
                                  filled
                                  dense
                                  v-model="usuario_revisado.rango"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Es requerido asignar un rango al usuario en este sector',
                                  ]"
                                  required
                                  label="Rango"
                                  prepend-icon="mdi-account-tie"
                                  :items="['Supervisor', 'Empleado']"
                                ></v-select>
                              </v-col>

                              <v-col>
                                <v-select
                                  :items="sectores"
                                  item-text="nombre"
                                  required
                                  rounded
                                  v-model="usuario_revisado.sector"
                                  dense
                                  return-object
                                  filled
                                  label="Seleccionar Sector"
                                  prepend-icon="mdi-card"
                                  :readonly="habilitarEditar"
                                  @change="vSelect"
                                ></v-select>
                                <!-- <v-text-field
                                required
                                :rules="[
                                  (v) =>
                                    !!v || 'Es requerido seleccionar un sector',
                                ]"
                                prepend-icon="mdi-card"
                                label="Sector"
                                :readonly="habilitarEditar"
                                v-if="habilitarEditar == true"
                                filled
                                dense
                                rounded
                                v-model="usuario_revisado.sector"
                              ></v-text-field> -->
                              </v-col>
                            </v-row>
                            <!-- Anotacion. Si el campo sector ha sufrido un cambio debera ejecutarse una funcion que lo cambie del sector actual y lo ingrese en otro -->
                            <v-row v-if="habilitarEditar == false || !valid2">
                              <v-col>
                                <v-btn
                                  block
                                  :disabled="habilitarEditar == true || !valid2"
                                  color="blue darken-4"
                                  text
                                  @click="editar"
                                  >Actualizar datos
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container v-else>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  :rules="[
                                    (v) =>
                                      !!v || 'Es requerido asignar un nombre',
                                  ]"
                                  required
                                  prepend-icon="mdi-account"
                                  label="Nombre"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_actual.nombre"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  type="number"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Es requerido asignar una identificacion',
                                  ]"
                                  required
                                  prepend-icon="mdi-card"
                                  label="Identificacion"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_actual.identificacion"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Es requerido asignar un correo electronico',
                                    (v) =>
                                      /.+@.+\..+/.test(v) ||
                                      'Coloca un email valido',
                                  ]"
                                  required
                                  prepend-icon="mdi-email"
                                  label="Correo"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_actual.correo"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                 <v-text-field
                                  :rules="[
                                    (v) =>
                                      !!v || 'Es requerido asignar un nombre de usuario',
                                  ]"
                                  required
                                  prepend-icon="mdi-account"
                                  label="Usuario"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_actual.usuario"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                 <v-text-field
                                  :rules="[
                                    (v) =>
                                      !!v || 'Es requerido asignar una clave',
                                  ]"
                                  required
                                  prepend-icon="mdi-lock"
                                  label="Clave"
                                  :readonly="habilitarEditar"
                                  filled
                                  dense
                                  rounded
                                  v-model="usuario_actual.clave"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-select
                                  :readonly="habilitarEditar"
                                  rounded
                                  filled
                                  dense
                                  v-model="usuario_actual.rango"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Es requerido asignar un rango al usuario en este sector',
                                  ]"
                                  required
                                  label="Rango"
                                  prepend-icon="mdi-account-tie"
                                  :items="['Supervisor', 'Empleado']"
                                ></v-select>
                              </v-col>

                              <v-col>
                                <v-select
                                  :items="sectores"
                                  item-text="nombre"
                                  required
                                  rounded
                                  dense
                                  return-object
                                  filled
                                  label="Seleccionar Sector"
                                  prepend-icon="mdi-card"
                                  :readonly="habilitarEditar"
                                  v-model="usuario_actual.sector"
                                  @change="vSelect"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <!-- Anotacion. Si el campo sector ha sufrido un cambio debera ejecutarse una funcion que lo cambie del sector actual y lo ingrese en otro -->
                            <v-row v-if="habilitarEditar == false || !valid2">
                              <v-col>
                                <v-btn
                                  block
                                  :disabled="habilitarEditar == true || !valid2"
                                  color="blue darken-4"
                                  text
                                  @click="editar"
                                  >Actualizar datos
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
            <v-col id="casos_asignados" cols="12" sm="5">
              <v-sheet rounded="" elevation="5" class="mr-15">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-sheet
                        color="indigo darken-4 "
                        dark
                        elevation="5"
                        rounded=""
                        class="mt-4"
                      >
                        <v-row>
                          <v-col>
                            <div
                              v-text="'Datos Laborales'"
                              class="headline font-weight-light text-center"
                            ></div>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-list>
                        <v-subheader>
                          <div
                            v-text="'Casos'"
                            class="subtitle-2 font-weight-light"
                          ></div>
                        </v-subheader>
                        <v-list-item>
                          <v-list-item-avatar
                            ><v-icon color="white" class="indigo"
                              >mdi-book</v-icon
                            ></v-list-item-avatar
                          >
                          <v-list-item-title
                            v-text="'Casos actuales'"
                            class="title font-weight-light"
                          >
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-avatar
                            ><v-icon color="white" class="green">
                              mdi-book</v-icon
                            ></v-list-item-avatar
                          >
                          <v-list-item-title
                            v-text="'Casos respondidos'"
                            class="title font-weight-light"
                          ></v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-avatar
                            ><v-icon color="white" class="red">
                              mdi-book</v-icon
                            ></v-list-item-avatar
                          >
                          <v-list-item-title
                            v-text="'Casos rechazados'"
                            class="title font-weight-light"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="dialogCrear" fullscreen>
      <v-sheet>
        <v-container fluid>
          <v-row>
            <v-col cols="1">
              <v-btn
                fab
                text
                small
                color="blue darken-4"
                class="mt-4"
                @click="
                  dialogCrear = false;
                  sw_select_aux = false;
                "
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col class="mx-auto">
              <v-sheet
                elevation="5"
                rounded=""
                color="indigo darken-4"
                dark
                class="mt-2 mr-15 ml-n8"
              >
                <v-container>
                  <v-row>
                    <v-col>
                      <div
                        v-text="'Crear Usuario'"
                        class="headline font-weight-light text-center"
                      ></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid" class="mx-10">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      prepend-icon="mdi-account-circle-outline"
                      :rules="[(v) => !!v || 'Es requerido asignar un nombre']"
                      required
                      v-model="nuevo_usuario.nombre"
                      color="indigo"
                      rounded
                      filled
                      dense
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      prepend-icon="mdi-card-account-details-outline"
                      :rules="[
                        (v) =>
                          !!v ||
                          'Es requerido asignar un numero de identificacion',
                      ]"
                      required
                      v-model="nuevo_usuario.identificacion"
                      color="indigo"
                      rounded
                      type="number"
                      filled
                      dense
                      label="Identificacion"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-email"
                      :rules="[
                        (v) =>
                          !!v || 'Es requerido asignar un correo electronico',
                        (v) => /.+@.+\..+/.test(v) || 'Colca un email valido',
                      ]"
                      required
                      v-model="nuevo_usuario.correo"
                      color="indigo"
                      rounded
                      filled
                      dense
                      label="Correo"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      prepend-icon="mdi-account-circle-outline"
                      :rules="[
                        (v) =>
                          !!v || 'Es requerido asignar un nombre de usuario',
                      ]"
                      required
                      v-model="nuevo_usuario.usuario"
                      color="indigo"
                      rounded
                      filled
                      dense
                      label="Usuario"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      prepend-icon="mdi-lock"
                      :rules="[
                        (v) =>
                          !!v || 'Es requerido asignar una clave a la cuenta',
                      ]"
                      required
                      v-model="nuevo_usuario.clave"
                      color="indigo"
                      rounded
                      filled
                      dense
                      label="Clave"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      rounded
                      filled
                      dense
                      v-model="nuevo_usuario.rango"
                      :rules="[
                        (v) =>
                          !!v ||
                          'Es requerido asignar un rango al usuario en este sector',
                      ]"
                      required
                      label="Rango"
                      prepend-icon="mdi-account-tie"
                      :items="['Supervisor', 'Empleado']"
                    ></v-select>
                      
                  </v-col>
                  <v-col cols="12">
                      <v-btn
                          block
                              class="mr-15"
                              :disabled="!valid"
                              style="color: white"
                              color="indigo"
                                  @click.stop="crear()"
                                >
                                  Crear
                            <v-icon> mdi-check</v-icon>
                      </v-btn>
                  </v-col>
                </v-row>
                
                
              </v-form>
            </v-col>
            
          </v-row>
          
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-main>
</template>
<script>
import Usuasiosector from "../../components/admin/Usuarios_sector";
import mixin_buscador from "../../mixins/buscador";
// import {mixin} from '../../mixins/buscador'
import { mapState } from "vuex";
const axios = require("axios").default;
export default {
  components: {
    Usuasiosector,
  },
  data() {
    return {
      img: require("../../assets/img.png"),
      usuarios_sector: [],
      nuevo_usuario: {
        nombre: "",
        identificacion: null,
        correo: "",
        usuario: "",
        clave: "",
        casos: [],
        notificaciones: [],
        rango: "",
      },
      dialogCrear: false,
      valid: true,
      valid2: true,
      id: "",
      dialogRevisar: false,

      habilitarEditar: true,
      sectores: [],
      usuario_revisado: {},
      usuario_actual: null,
      //Del sector seleccionado en el v-select
      info_sector_edited: {},
      //aux para corregir el error de select
      sw_select_aux: false,
    };
  },
  mixins: [mixin_buscador],
  watch: {
    actualizarUsuariosSector: function () {
      console.log("prueba", this.actualizarUsuariosSector);

      this.dialogCrear = true;
    },
    usuario_actual(item) {
      console.log("USUARIO ACTUAL");
      console.log(item);
      console.log(this.usuario_actual);
    },
    itemsFilter: {
      handler(val, oldVal) {
        console.log(val);
        console.log(oldVal,'1');
        
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      actualizarUsuariosSector: function (state) {
        return state.admin.actualizarUsuariosSector;
      },
    }),
  },
  async created() {
    console.log(this.$route.params);
    this.id = this.$route.params.data.objectId;

    await this.iniciar();
    this.$store.commit("auth", "parse");
    let sectores = await axios.get(
      " https://siett.educandote.co/parse/classes/prueba01Sector/",
      {
        headers: {
          "Content-Type": "application/json",
          "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
          "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
          Authorization: this.$store.state.header,
        },
      }
    );
    console.log("Sectores: ");
    console.log(sectores.data.results);
    this.sectores = [];
    this.sectores = sectores.data.results; 
  },
  methods: {
    
    async iniciar() {
      let buscar = JSON.stringify({"sector":this.$route.params.data.objectId})
      buscar = encodeURIComponent(buscar)
      let response = await this.$store.dispatch('llamado_get',
      {url: 'https://siett.educandote.co/parse/classes/prueba03Usuarios?where='+buscar,tipo_header:'parse'})
      console.log(response)
      this.items = [...response.data.results]
      this.itemsFilter = [...response.data.results]
    },
    async revisar(usuario) {
      console.log(usuario);
      this.$store.commit("auth", "parse");
      console.log(usuario);
      //Falta colocar un load
   
      try {
        let response = await axios.get(
          " https://siett.educandote.co/parse/classes/prueba03Usuarios/" +
            usuario.objectId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        let response2 = await axios.get(
          " https://siett.educandote.co/parse/classes/prueba01Login/" +
             usuario.userId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(response);
        console.log(response2);

        this.usuario_revisado = {...response.data, ...response2.data, objectId: response.data.objectId, userId: response2.data.objectId };
        
        console.log(this.sectores)
        console.log(this.usuario_revisado)
        let sector = this.sectores.filter(item => item.objectId.toLowerCase().indexOf(response.data.sector.toLowerCase()) !== -1)
        console.log(sector)
        this.usuario_revisado.sector = sector[0]
        this.usuario_actual = { ...this.usuario_revisado };
        console.log(this.usuario_actual)
        //Para actualizar la interfaz
        this.dialogRevisar = true;
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Algo ha salido mal consultando la informacion del usuario " +
            usuario.nombre
        );

        //let array1 = ['1','2','3','4']
      }
    },
    async editar() {
      this.$store.commit("cargando");
      console.log(this.info_sector_edited)
      console.log(
        "objecid del nuevo/viejo Sector: " + this.info_sector_edited.objectId
      );
      console.log(this.usuario_actual);
      console.log(this.usuario_revisado);

      console.log(this.$route.params.data.objectId)

      try {
        //Se busca en pruebaUsuarios
        this.$store.commit("auth", "parse");
        let validacion = await this.validacion(this.usuario_actual.usuario,this.usuario_actual.identificacion) //valida que no exista una cuenta con dichas credenciales
        console.log(validacion)
        if(validacion.exito){
           let user_edited_usuarios = {
                nombre: this.usuario_actual.nombre,
                identificacion: this.usuario_actual.identificacion,
                correo: this.usuario_actual.correo,
                rango: this.usuario_actual.rango,
                sector: this.usuario_actual.sector.objectId,
              };
              console.log(user_edited_usuarios);

              this.$store.commit("auth", "parse");
              let res_usuario_edited = await axios.put(
                "https://siett.educandote.co/parse/classes/prueba03Usuarios/" +
                  this.usuario_actual.objectId,
                  user_edited_usuarios,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                    "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                    Authorization: this.$store.state.header,
                  },
                }
              );
              console.log(res_usuario_edited);
              //--------------------------------------------------

              //Se edita de pruebaLogin
              let user_edited_login = {
                usuario: this.usuario_actual.usuario,
                clave: this.usuario_actual.clave,
              };
              console.log(user_edited_login);
              this.$store.commit("auth", "parse");
              let res_login_edited = await axios.put(
                "https://siett.educandote.co/parse/classes/prueba01Login/" +
                  this.usuario_actual.userId,
                user_edited_login,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                    "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                    Authorization: this.$store.state.header,
                  },
                }
              );
              console.log(res_login_edited);
              this.$store.commit('exitoso', "Los datos del usuario "+this.usuario_actual.nombre+ " Han sido editados de forma exitosa");
              console.log(this.itemsFilter)
              let varAux = this.itemsFilter.filter((item) => item.objectId.indexOf(this.usuario_actual.objectId) !== -1);
              console.log(varAux[0]);
              var i = this.itemsFilter.indexOf(varAux[0]);
              if(this.$route.params.data.objectId!=this.usuario_actual.sector.objectId){
                if (i != -1) {
                  this.itemsFilter.splice(i, 1);
                  this.items.splice(i,1)
                }
              }else{
                console.log('Actualizar datos')
                if(i!= -1){
                  Object.assign(this.itemsFilter[i], this.usuario_actual)
                  Object.assign(this.items[i], this.usuario_actual)
                }

              }

              this.dialogRevisar = false
        }else{
           console.log(validacion)
           this.$store.commit("error",validacion.mensaje);
        }
        
      } catch (error) {
        console.log(error);
        this.$store.commit("error", "Ha ocurrido un error inesperado "+error.message);
      }
    },
    async eliminar(usuario) {
      this.$store.commit("cargando");
      try {
        console.log(usuario);
        console.log(this.$route.params.data.objectId);

        //Se elimina el usuario del array de prueba01Sector
        this.$store.commit("auth", "parse");
        // Se elimina de prueba03Usuarios
        let res_usuarios_eliminado = await axios.delete(
          " https://siett.educandote.co/parse/classes/prueba03Usuarios/" +
           usuario.objectId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(res_usuarios_eliminado.data);

        //Buscar usuario en prueba01Login para elimninarlo
        this.$store.commit("auth", "parse");
        let res_login = await axios.get(
          " https://siett.educandote.co/parse/classes/prueba01Login/" +
            usuario.userId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log("Found user to elimination/Endpoint: prueba01Login");
        console.log(res_login);
        //Se elimina de prueba01Login
        let varAux = this.itemsFilter.filter((item) => item.objectId.indexOf(usuario.objectId) !== -1);
        console.log(varAux[0]);
        var i = this.itemsFilter.indexOf(varAux[0]);
        if (i != -1) {
          this.itemsFilter.splice(i, 1);
          this.items.splice(i,1)
        }
        this.$store.commit(
          "exitoso",
          "El usuario ha sido eliminado de manera exitosa"
        );
      } catch (error) {
        console.log(error);
        this.$store.commit("error", "Error al eliminar usuario "+error.message);
      }
    },
    async validacion(usuario,identificacion){
      try {
        let buscar_login = JSON.stringify({
          usuario: usuario,
        });

        buscar_login = encodeURIComponent(buscar_login);
        let login = await this.$store.dispatch('llamado_get',{
          url:"https://siett.educandote.co/parse/classes/prueba01Login?where="+buscar_login,
          tipo_header: "parse"
        })

        if(login.data.results.length<=1){ //Es menor o igual a 1 en dado caso se quiera editar no genere conflicto con sus propios datos
           let buscar_usuario = JSON.stringify({
            identificacion:  identificacion,
          });

          buscar_usuario = encodeURIComponent(buscar_usuario);
          let usuario = await this.$store.dispatch('llamado_get',{
            url: "https://siett.educandote.co/parse/classes/prueba03Usuarios?where="+buscar_usuario,
            tipo_header: "parse",
          })

          if(usuario.data.results.length<=1){ //Es menor o igual a 1 en dado caso se quiera editar no genere conflicto con sus propios datos
            return {exito: true, mensaje: "El usuario puede ser creado"} 
          }else{
            
            return {exito: false, mensaje: "Ya existe un usuario con esta identificacion por favor intenta crear..."} 
          }
          
        }else{
          return {exito: false, mensaje: "Ya existe una cuenta con ese usuario, por favor ingrese un nombre de usuario distinto"}
        }        
      } catch (error) {
        console.log(error)
        return {exitoso:false, mensaje: "Algo ha salido mal validando los datos del usuario, por favor intente mas tarde, si el error persiste informe a soporte "+error.message }
      }
    },
    async crear() {
      this.$store.commit("cargando");
      this.$store.commit("auth", "parse");
      console.log(this.$store.state.header);
      console.log(this.nuevo_usuario);
      console.log(this.$route.params)

      try {
        //Datos con los que se va a loguear la persona

        let validacion = await this.validacion(this.nuevo_usuario.usuario,this.nuevo_usuario.identificacion) //valida que no exista una cuenta con dichas credenciales
        console.log(validacion)
        if(validacion.exito){
            let response3 = await axios.post(
              "https://siett.educandote.co/parse/classes/prueba01Login",
              {
                usuario: this.nuevo_usuario.usuario,
                clave: this.nuevo_usuario.clave,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                  "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                  Authorization: this.$store.state.header,
                },
              }
            );

            console.log(response3);

            //se crea el usuario en la plataforma
            let response = await axios.post(
              " https://siett.educandote.co/parse/classes/prueba03Usuarios",
              {
                nombre: this.nuevo_usuario.nombre, //fry
                identificacion: this.nuevo_usuario.identificacion , //1004373196
                sector: this.$route.params.data.objectId,
                correo: this.nuevo_usuario.correo, //edgarsimondsm@gmail.com
                casos: this.nuevo_usuario.casos,
                notificaciones: this.nuevo_usuario.notificaciones,
                rango: this.nuevo_usuario.rango, //Valida si es admin de sector o no
                userId: response3.data.objectId
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                  "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                  Authorization: this.$store.state.header,
                },
              }
            );

            console.log("Iniciando"); //El endpoint de prueba01Sector tiene un array llamado empleados_sector
            //Para agregar un nuevo empleado a dicho utilizamos una funcion tipo put agregando el nuevo usuario
          

            console.log(response);
            let user = {
                nombre: this.nuevo_usuario.nombre, //fry
                identificacion: this.nuevo_usuario.identificacion, //1004373196
                sector: this.$route.params.data.objectId,
                correo: this.nuevo_usuario.correo, //edgarsimondsm@gmail.com
                casos: this.nuevo_usuario.casos,
                notificaciones: this.nuevo_usuario.notificaciones,
                rango: this.nuevo_usuario.rango, //Valida si es admin de sector o no
                userId: response3.data.objectId,
                objectId: response.data.objectId,
            }
            console.log(this.nuevo_usuario);
            this.items.unshift(user);
            this.itemsFilter.unshift(user)
            console.log("aqui");
            console.log(this.items);
            console.log(this.itemsFilter);

            //this.$refs.form.reset();

            this.$store.commit(
              "exitoso",
              "El usuario ha sido creado de manera exitosa"
            );

            this.dialogCrear = false;
            this.nuevo_usuario = {
              nombre: "",
              identificacion: null,
              correo: "",
              usuario: "",
              clave: "",
              casos: [],
              notificaciones: [],
              rango: "",
            };
            this.$refs.form.resetValidation();
        }else{
          console.log(validacion)
          this.$store.commit("error",validacion.mensaje);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Algo ha salido mal creando el usuario, por favor intente mas tarde, si el error persiste informe a soporte "+error.message
        );
      }
    },
    vSelect(event) {
      console.log(event.objectId);
      this.info_sector_edited = event;
      console.log(this.info_sector_edited);
    },
  },
};
</script>